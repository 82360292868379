import { lazy , useEffect, useState } from 'react';

/// Components
import Index from "./jsx";
import { useDispatch, useSelector } from 'react-redux';
import { checkAutoLogin } from './services/AuthService';
import { isAuthenticated } from './store/selectors/AuthSelectors';
/// Style
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";
import { ContextApi } from './store/ContextApi';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import { getHomeDataFunc } from './store/custom-actions/settings-actions/dahsboard/GetHomeDataSlicer';

function App (props) {
    const dispatch = useDispatch();
    useEffect(() => {
        checkAutoLogin(dispatch, props.history);
    }, [dispatch, props.history]);
    const {data:languageData} = useSelector(
      state => state.getAppLanguageSlicer
    )
    const changeFavicon = (iconURL) => {
      let link = document.querySelector("link[rel~='icon']");
      if (!link) {
          link = document.createElement('link');
          link.rel = 'icon';
          document.getElementsByTagName('head')[0].appendChild(link);
      }
      link.href = iconURL;
    };
    const changeTitle = (title) => {
      document.title = title;
      // link.href = iconURL;
    };
    const [settings , setSettings] = useState({
      site_title : "top ccsa",
      site_footer_copyright : "copyright  2024  All right reserved by  <a href='https://google.com/'>top growth</a>",
      site_logo : "",
      site_color : "#111d5c",
      site_global_currency : "EGP",
      facebook_link: "https://google.com/",
      youtube_link: "https://google.com/",
      twitter_link: "https://google.com/",
      instagram_link: "https://google.com/",
      linkedin_link: "https://google.com/",
      tax_fee_amount: 0,
      tax_fee_type: "percentage",
      contact_phone: "0154646822",
      contact_email: "company@app.com",
      contact_address: "cario"
    })
    const global_data = {
        dashboard_URL_API : "https://apis-books.topgrowth-eg.com/api/dashboard",
        main_headers : (
          axios.defaults.headers.common["Content-Type"] = "application/json",
          axios.defaults.headers.common["Accept"] = "application/json"
        ),
        homeSettings : settings
      }
      const homeData_url = `${global_data.dashboard_URL_API}/global-settings`;
      const {data:mainData} = useSelector(
        state => state.getHomeDataSlicer
      )
      useEffect(() => {
        dispatch(getHomeDataFunc(homeData_url))
      },[])
      useEffect(() => {
        if(mainData) {
          setSettings(mainData)
        }
      },[mainData])

      useEffect(() => {
        // Change the favicon to a new icon URL
        changeFavicon(settings.site_logo);
        changeTitle(settings.site_title);
        
        // You can also revert back to the original favicon on unmount if needed
        return () => {
            changeFavicon(settings.site_logo);
            changeTitle(settings.site_title)
        };
      }, [settings]);
      
      // useEffect(() => {
      //   dispatch(getAppLanguageFunc(appLang_url))
      // },[])
      
      // useEffect(() => {
      //   const fetchTranslations = async () => {
      //     if (languageData) {
      //       const { defaultLang, all_word } = languageData;
      //       if (defaultLang && all_word) {
      //         const { direction, slug } = defaultLang;
      //         document.documentElement.dir = direction; // Set the direction dynamically
    
      //         i18n.addResources(slug, 'translation', all_word);
      //         i18n.changeLanguage(slug);
    
      //         if (slug === 'ar') {
      //           const existingLink = document.querySelector('link[href="./assets/css/style-rtl.css"]');
      //           if (!existingLink) {
      //             const rtlStyleLink = document.createElement('link');
      //             rtlStyleLink.href = './assets/css/style-rtl.css';
      //             rtlStyleLink.rel = 'stylesheet';
      //             document.head.appendChild(rtlStyleLink);
      //           }
      //         }
      //       } else {
      //         console.error('Invalid language data structure', languageData);
      //       }
      //     }
      //   };
    
      //   fetchTranslations();
      // }, [languageData]);



      return (
        <>
          <ContextApi.Provider value={global_data}>
            <Index/>
          </ContextApi.Provider>
          <ToastContainer className="custom-toast-container"/>
        </>
      )


};

const mapStateToProps = (state) => {
    return {
        isAuthenticated: isAuthenticated(state),
    };
};

// export default withRouter(connect(mapStateToProps)(App)); 
export default App; 

